@keyframes bounceIn {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes bounceOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  20% {
    transform: translateY(-10px);
  }
  50%,
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
@keyframes bounceIn {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.arrow-icon {
  animation: bounceIn 2s infinite forwards;
}
.arrow-icon.up {
  transform: rotate(180deg);
}
